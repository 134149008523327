<template>
  <div>
    <b-modal
      id="attendance-review-modal"
      size="xl"
      hide-header
      hide-footer
      centered
      no-stacking
      @show="onShow"
    >
      <div class="header d-flex flex-row justify-content-between">
        <p class="title">Impressão e assinatura digital</p>
        <Close
          class="close"
          @click="$bvModal.hide('attendance-review-modal')"
        />
      </div>
      <div class="body">
        <div v-if="canSave">
          <div v-if="!hideAttendanceOptions">
            <h6 class="title title1">O atendimento é presencial ou online?</h6>
            <div class="form-check-inline form-check-inline1">
              <label class="form-check-label" @click="attendanceType = 1">
                <input
                  type="radio"
                  class="form-check-input"
                  name="type_attendance"
                  :checked="attendanceType === 1"
                />Presencial
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label" @click="attendanceType = 2">
                <input
                  type="radio"
                  class="form-check-input"
                  name="type_attendance"
                />
                <span class="online-span">Online</span>
              </label>
            </div>
            <hr />
          </div>
          <div v-if="attendanceType > 0">
            <p class="text" style="margin-top: 15px; margin-bottom: 15px">
              Selecione na lista abaixo quais documentos deseja imprimir e quais
              deseja <strong>assinar digitalmente</strong> para compartilhar com
              seu paciente.
            </p>
            <perfect-scrollbar :options="{ suppressScrollX: true }">
              <div class="div-table">
                <table class="attendance-review-table">
                  <tr>
                    <th>Documentos</th>
                    <th v-if="attendanceType == 1">Imprimir</th>
                    <th>Assinar</th>
                  </tr>

                  <tr
                    v-for="(review, index) in filteredReviews(attendance?.type)"
                    id="attendance-review"
                    :key="index"
                  >
                    <td class="tdLabel" v-if="review?.active">
                      {{ review.label }}
                    </td>
                    <td
                      v-if="
                        attendanceType == 1 && (review?.active || review.toSign)
                      "
                      align="center"
                    >
                      <label
                        :id="`print_${index}`"
                        class="checkbox"
                        align="center"
                      >
                        <input
                          type="checkbox"
                          v-model="review.toPrint"
                          :disabled="disabledCheckbox(review)"
                        />

                        <b-tooltip
                          v-if="disabledCheckbox(review)"
                          :target="`print_${index}`"
                          triggers="hover"
                          placement="bottom"
                        >
                          Para imprimir, assinar ou enviar este documento,
                          preencha o campo dentro da consulta.
                        </b-tooltip>

                        <span
                          class="checkmark"
                          :class="{ disabled: disabledCheckbox(review) }"
                        >
                          <Check />
                        </span>
                      </label>
                    </td>

                    <td
                      align="center"
                      v-if="
                        attendanceType == 1 && (review?.active || review.toSign)
                      "
                    >
                      <label
                        :id="`signature_${index}`"
                        class="checkbox"
                        align="center"
                      >
                        <input
                          type="checkbox"
                          v-model="review.toSign"
                          :disabled="disableToSign(review)"
                        />

                        <b-tooltip
                          v-if="disableToSign(review)"
                          :target="`signature_${index}`"
                          triggers="hover"
                          placement="bottom"
                        >
                          Para imprimir, assinar ou enviar este documento,
                          preencha o campo dentro da consulta.
                        </b-tooltip>

                        <span
                          class="checkmark"
                          :class="{ disabled: disableToSign(review) }"
                        >
                          <Check />
                        </span>
                      </label>
                    </td>
                  </tr>
                </table>
              </div>
            </perfect-scrollbar>
          </div>
        </div>

        <div v-else>
          <p class="message-box warning">
            <InfoCircle class="icon" />
            <span>
              Alguns campos precisam ser <b>preenchidos</b> da sua consulta.
            </span>
          </p>

          <ul class="required-fields">
            <li v-if="!this.form.cid.value.length">CID</li>
            <li v-if="!this.form.hipoteseDiagnostica.value.length">
              Diagnóstico
            </li>
          </ul>
        </div>

        <b-row v-if="attendanceType > 0" style="margin-top: 25px">
          <b-col class="text-right">
            <label for="save-model" class="checkbox mb-5" v-if="updatedForm">
              <input type="checkbox" id="save-model" v-model="saveCustomForm" />

              <span class="checkmark">
                <Check />
              </span>

              Salvar modelo personalizado
            </label>

            <b-button
              variant="link"
              @click="$bvModal.hide('attendance-review-modal')"
            >
              Continuar editando
            </b-button>
            <b-button
              variant="primary"
              :disabled="!canEdit"
              @click="doFinishAttendance"
            >
              {{
                !checkAttendanceReviewPrint
                  ? 'Salvar'
                  : attendanceType == 1
                  ? 'Salvar e Imprimir'
                  : 'Salvar e assinar'
              }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import printableDocsMixin from '@/mixins/Attendance/printableDocsMixin'

export default {
  name: 'attendance-review-modal',
  props: {
    hideAttendanceOptions: Boolean,
    customReviews: Array,
    noCid: Boolean
  },
  components: {
    InfoCircle: () => import('@/assets/icons/info-circle.svg'),
    Check: () => import('@/assets/icons/check.svg'),
    Close: () => import('@/assets/icons/close.svg')
  },
  computed: {
    ...mapGetters(['certificate']),
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      updatedForm: state => state.attendance.form.updated,
      attendance: state => state.attendance.attendance,
      form: state => state.attendance.form
    })
  },
  mixins: [printableDocsMixin('customReviews')],
  data() {
    return {
      attendanceType: 1,
      toPrint: [],
      toSign: [],
      saveCustomForm: false,
      isDifferent: null
    }
  },
  methods: {
    ...mapActions('attendance', ['finishAttendance']),
    canSave() {
      return (
        this.form.cid.value.length && this.form.hipoteseDiagnostica.value.length
      )
    },
    disabledCheckbox(review) {
      return ![5, 13].includes(review.identifier)
        ? !this.filled.includes(review.identifier)
        : (review.identifier === 5 &&
            !this.filled.includes(5) &&
            !this.filled.includes(9)) ||
            (review.identifier === 13 &&
              !this.filled.includes(13) &&
              !this.filled.includes(22))
    },
    async doFinishAttendance() {
      this.updateForm()
      const payload = this.reviews.reduce(
        (acc, review) => {
          if (review.toPrint) {
            acc.toPrint.push(review.identifier)
          }
          if (review.toSign) {
            acc.toSign.push(review.identifier)
          }
          return acc
        },
        { toPrint: [], toSign: [0] }
      )

      if (this.filled.includes(9)) {
        if (payload.toPrint.includes(5)) {
          payload.toPrint.push(9)
        }
        if (payload.toSign.includes(5)) {
          payload.toSign.push(9)
        }
      }
      if (this.filled.includes(22)) {
        if (payload.toPrint.includes(13)) {
          payload.toPrint.push(22)
        }
        if (payload.toSign.includes(13)) {
          payload.toSign.push(22)
        }
      }
      this.filled.includes(39)
      && payload.toPrint.includes(39)
      && payload.toPrint.push(40)
      && payload.toPrint.push(41)
      && payload.toPrint.push(42)
      && payload.toPrint.push(43)

      payload.noCid = this.noCid
      await this.finishAttendance(payload)
      this.$emit('onFinishAttendance')
      this.$bvModal.hide('attendance-review-modal')
    },
    updateForm() {
      if (!this.saveCustomForm) return
      const selectedForm = this.$store.state.attendance.form.selected
      const forms = []
      Object.keys(this.form).map(key => {
        if (!this.form[key] || !this.form[key].id) return
        forms.push({
          id: this.form[key].id,
          name: this.form[key].name,
          opened: this.form[key].opened,
          showing: this.form[key].showing
        })
      })
      this.api
        .updateForm(selectedForm.value, { forms })
        .then(() => console.log('updated form'))
        .catch(err => console.log('Failed updated form ', err))
    },
    disableToSign(review) {
      if (
        !this.certificate ||
        !this.certificate.active ||
        this.certificate.active === 'inative'
      )
        return true
      return review.identifier !== 5
        ? !this.filled.includes(review.identifier)
        : !this.filled.includes(5) && !this.filled.includes(9)
    }
  }
}
</script>

<style lang="scss">
#attendance-review-modal {
  font-family: 'Nunito Sans';

  .modal-body {
    padding: 0 !important;

    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-weight: 600;
        font-size: 18px;
        color: var(--type-active);
        margin: 0;
      }

      .title1 {
        color: #0c1d59;
        font-family: Red Hat Display;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 134%;
      }

      .form-check-inline1 {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        color: var(--type-active);
      }

      .close {
        width: 24px;
        height: 24px;
        fill: black;
        cursor: pointer;
      }
    }

    .body {
      .online-span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        color: var(--type-active);
      }
      padding: 24px;

      .div-table {
        max-height: 62vh;
      }

      .attendance-review-table {
        width: -webkit-fill-available;
        tr {
          border-bottom: 1px solid var(--neutral-200);
          th {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
            color: var(--type-active);
            text-align: center;
          }
          th:first-child {
            text-align: left !important;
          }
        }
      }

      .message-box {
        background-color: #e6f9ff;
        border-radius: 8px;
        padding: 16px 26px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 0 0 24px 0;
        align-items: center;
        color: var(--type-active);

        b {
          font-weight: 700;
        }

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 25px;
          stroke: #0088b3;
        }

        &.warning {
          justify-content: start;
          background-color: #fff0e6;

          .icon {
            stroke: #b34b00;
          }
        }
      }

      .required-fields {
        list-style-type: none;
        margin: 0 0 24px 0;
        padding: 0;

        li {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: var(--type-active);
          padding: 8px 0;
          border-bottom: 1px solid var(--neutral-300);
        }
      }

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 8px;
      }

      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
        margin-bottom: 32px;
      }

      .options {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--type-active);
      }
      .checkbox {
        margin-top: -12px;
        display: initial !important;

        .disabled {
          background-color: var(--neutral-200);
          border: none;
        }
      }
      .checkbox.bold {
        font-weight: 700;
        margin-bottom: 16px;
      }
      .tdLabel {
        font-size: 16px;
        color: var(--type-active);
        font-weight: 400;
        /* display: block; */
        padding: 10px 0px;
      }
    }
  }

  .tooltip-inner {
    width: 256px;
  }
}
#attendance-review {
  .tdLabel {
    font-size: 16px;
    color: var(--type-active);
    font-weight: 400;
    padding: 10px 0px;
  }
  .checkbox {
    margin-top: -12px;
    display: initial !important;
    .disabled {
      background-color: var(--neutral-200);
      border: none;
      svg {
        display: none;
      }
    }
  }
  .checkbox.bold {
    font-weight: 700;
    margin-bottom: 16px;
  }
  .tooltip-inner {
    width: 256px;
  }
}
</style>
